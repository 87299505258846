
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

import {ElUpload, ElDialog} from 'element-plus';

@Options({
  components: {
    ElUpload,
    ElDialog
  },
  computed: {}
})
export default class Proxy extends Vue {
  @Prop()
  modelValue!: any[];
  @Prop()
  action!: string;
  @Prop()
  tips!: string;
  dialogImage = '';
  dialogVisible = false;
  disabled = false;
  file = {};
  handlePictureCardPreview(file: any) {
    this.dialogImage = file;
    this.dialogVisible = true;
  }
  change(file: any, files: []) {
    // 延迟一会儿  不然会有一个动画的bug
    setTimeout(() => {
      this.$emit('update:modelValue', files);
    }, 1000);
  }
}

<template>
  <el-upload
    :action="action"
    list-type="picture-card"
    :on-preview="handlePictureCardPreview"
    :on-remove="change"
    :on-change="change"
    :file-list="modelValue"
    v-bind="$attrs"
  >
    <!--    <i v-if="$attrs.limit && modelValue.length < $attrs.limit" class="el-icon-plus"></i>-->
    <div class="uploadBox">
      <i class="el-icon-plus avatar-uploader-icon"></i>
      <div v-if="tips" class="el-upload__tip">{{ tips }}</div>
    </div>
  </el-upload>
  <el-dialog v-model="dialogVisible">
    <video
      v-if="dialogImage.name.indexOf('.mp4') >= 0 || dialogImage.name.indexOf('.MP4') >= 0"
      style="width: 100%"
      :src="dialogImage.url"
      controls
    ></video>
    <img v-else style="width: 100%" :src="dialogImage.url" alt="" />
  </el-dialog>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

import {ElUpload, ElDialog} from 'element-plus';

@Options({
  components: {
    ElUpload,
    ElDialog
  },
  computed: {}
})
export default class Proxy extends Vue {
  @Prop()
  modelValue!: any[];
  @Prop()
  action!: string;
  @Prop()
  tips!: string;
  dialogImage = '';
  dialogVisible = false;
  disabled = false;
  file = {};
  handlePictureCardPreview(file: any) {
    this.dialogImage = file;
    this.dialogVisible = true;
  }
  change(file: any, files: []) {
    // 延迟一会儿  不然会有一个动画的bug
    setTimeout(() => {
      this.$emit('update:modelValue', files);
    }, 1000);
  }
}
</script>

<style lang="scss">
.el-upload-list__item {
  transition: none;
}
.el-dialog__headerbtn {
  z-index: 10;
}
.uploadBox {
  width: 100%;
  height: 100%;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.el-upload__tip {
  padding: 0 10px;
  line-height: 20px;
  text-align: left;
  word-break: break-all;
  opacity: 0.5;
}
</style>
